var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LiefengContent',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("自定义报表")]},proxy:true},{key:"toolsbarLeft",fn:function(){return [_c('Input',{staticStyle:{"width":"100%"},attrs:{"search":"","enter-button":"查询","placeholder":"输入报表名称关键词"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('Select',{staticStyle:{"width":"120px"},attrs:{"slot":"prepend"},slot:"prepend",model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}},[_c('Option',{attrs:{"value":"1"}},[_vm._v("全部报表")]),_c('Option',{attrs:{"value":"3"}},[_vm._v("我的报表")])],1),_c('Select',{staticStyle:{"width":"100px"},attrs:{"slot":"prepend"},slot:"prepend",model:{value:(_vm.share),callback:function ($$v) {_vm.share=$$v},expression:"share"}},[_c('Option',{attrs:{"value":"1"}},[_vm._v("全部分类")]),_c('Option',{attrs:{"value":"2"}},[_vm._v("私有")]),_c('Option',{attrs:{"value":"3"}},[_vm._v("共享")])],1)],1)]},proxy:true},{key:"toolsbarRight",fn:function(){return [_c('Button',{attrs:{"type":"primary","icon":"md-add"},on:{"click":function () {
          _vm.showCustom('创建报表', 0);
        }}},[_vm._v("创建报表")])]},proxy:true},{key:"contentArea",fn:function(){return [_c('List',{staticClass:"clist",attrs:{"border":""}},_vm._l((_vm.reportList),function(report,index){return _c('ListItem',{key:'R' + index},[_c('div',{staticStyle:{"width":"100%","cursor":"pointer"},on:{"click":function () {
              _vm.viewReport(report.id);
            }}},[_c('ListItemMeta',{attrs:{"title":report.title,"description":report.desc}})],1),_c('template',{slot:"action"},[_c('li',[_c('a',{on:{"click":function () {
                  _vm.shareReport(report.share,report.id);
                }}},[_vm._v(_vm._s(report.share == 1 ? "共享" : "私有"))])]),_c('li',[_c('a',{on:{"click":function () {
                  _vm.showCustom('修改报表:' + report.title, report.id);
                }}},[_vm._v("修改")])]),_c('li',[_c('a',{on:{"click":function () {
                  _vm.deleteReport(report.id);
                }}},[_vm._v("删除")])])])],2)}),1),_c('Page',{staticStyle:{"float":"right","margin":"10px"},attrs:{"current":_vm.curPage,"page-size":_vm.pageSize,"total":_vm.total,"page-size-opts":_vm.pagesizeOpts,"size":"small","show-total":"","show-elevator":"","show-sizer":""},on:{"on-change":_vm.currentPage,"on-page-size-change":_vm.hadlePageSize}})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }