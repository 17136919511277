<template>
  <LiefengContent>
    <template v-slot:title>自定义报表</template>
    <template v-slot:toolsbarLeft>
      <Input
        v-model="keyword"
        search
        enter-button="查询"
        placeholder="输入报表名称关键词"
        style="width: 100%"
      >
        <Select v-model="username" slot="prepend" style="width: 120px">
          <Option value="1">全部报表</Option>
          <Option value="3">我的报表</Option>
        </Select>
        <Select v-model="share" slot="prepend" style="width: 100px">
          <Option value="1">全部分类</Option>
          <Option value="2">私有</Option>
          <Option value="3">共享</Option>
        </Select>
      </Input>
    </template>
    <template v-slot:toolsbarRight>
      <Button
        type="primary"
        icon="md-add"
        @click="
          () => {
            showCustom('创建报表', 0);
          }
        "
        >创建报表</Button
      ></template
    >
    <template v-slot:contentArea>
      <List class="clist" border>
        <ListItem v-for="(report, index) in reportList" :key="'R' + index">
          <div
            style="width: 100%; cursor: pointer"
            @click="
              () => {
                viewReport(report.id);
              }
            "
          >
            <ListItemMeta :title="report.title" :description="report.desc" />
          </div>
          <template slot="action">
            <li>
                <a
                @click="
                  () => {
                    shareReport(report.share,report.id);
                  }
                "
                >{{ report.share == 1 ? "共享" : "私有" }}</a
              >
              
            </li>
            <li>
              <a
                @click="
                  () => {
                    showCustom('修改报表:' + report.title, report.id);
                  }
                "
                >修改</a
              >
            </li>
            <li>
              <a
                @click="
                  () => {
                    deleteReport(report.id);
                  }
                "
                >删除</a
              >
            </li>
          </template>
        </ListItem>
      </List>
      <Page
        style="float: right; margin: 10px"
        @on-change="currentPage"
        :current="curPage"
        @on-page-size-change="hadlePageSize"
        :page-size="pageSize"
        :total="total"
        :page-size-opts="pagesizeOpts"
        size="small"
        show-total
        show-elevator
        show-sizer
      />
    </template>
  </LiefengContent>
</template>

<script>
//@route('/report')
import LiefengContent from "@/components/LiefengContent";
export default {
  data() {
    return {
      keyword: "",
      username: "1",
      share: "1",
      reportList: [
        {
          id: 1,
          title: "用户量周报",
          desc: "每周用户量汇总表",
          createTime: "2021-03-25 09:09:00",
          username: "canny",
          share: 1,
        },
        {
          id: 1,
          title: "用户量年报",
          desc: "每年用户量汇总表",
          createTime: "2021-03-25 09:09:00",
          username: "canny",
          share: 0,
        },
        {
          id: 1,
          title: "用户量月报",
          desc: "每月用户量汇总表",
          createTime: "2021-03-25 09:09:00",
          username: "canny",
          share: 1,
        },
        {
          id: 1,
          title: "用户量季报",
          desc: "每季用户量汇总表",
          createTime: "2021-03-25 09:09:00",
          username: "canny",
          share: 1,
        },
      ],
    };
  },
  methods: {
    showCustom(title, id) {
      top.layer.open({
        type: 2,
        title: title,
        area: ["1200px", "80%"],
        maxmin: false,
        content: "/page#/reportcustom?id=" + id,
      });
    },
    deleteReport(id) {
       this.$Modal.confirm({
                    content: '正在删除该报表，是否继续？',
                    loading: true,
                    onOk: () => {

                    }
                });
    },
    shareReport(share,id) {
      this.$Modal.confirm({
                    content: (share==1?'正在设置该报表为私有，':'正在共享该报表，')+'是否继续？',
                    loading: true,
                    onOk: () => {

                    }
                });
    },
    viewReport(id) {
      top.layer.open({
        type: 2,
        title: "查看",
        area: ["1200px", "80%"],
        maxmin: false,
        content: "/page#/reportview?id=" + id,
      });
    },
  },
  mounted() {},
  components: {
    LiefengContent,
  },
};
</script>
    
<style scoped lang='less'>
.clist {
  background: #fff;
  height: calc(100% - 60px);
  overflow: auto;
}
</style>